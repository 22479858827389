.MaterialContent a {
    font-size: 20px;
}

.MaterialContent img {
    width: 100%;
}

.MaterialContent h1 {
    margin-bottom: 20px;
}

.MaterialContent h2 {
    margin-bottom: 20px;
}

.MaterialContent h3 {
    margin-bottom: 20px;
}

.MaterialContent h4 {
    font-size: 16px;
    margin-bottom: 20px;
}

.MaterialContent h5 {
    font-size: 14px;
    margin-bottom: 20px;
}

.MaterialContent h6 {
    font-size: 12px;
    margin-bottom: 20px;
}

.MaterialContent img {
    object-fit: cover;
    max-width:100% !important;
    height:auto !important;
}