.button {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    border: none;

    font-family: var(--font-ibm);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

    cursor: pointer;
    text-decoration: none;

    background: var(--color-white);
    color: var(--color-emerald);
}

.button:hover {
    background: var(--color-blue);
    color: var(--color-emerald);
}

.button:focus-visible {
    background: var(--color-blue);
    color: var(--color-emerald);

    outline: 3px solid var(--color-blue);
    outline-offset: 2px;
}

.button:active {
    background: var(--color-light-gray);
    color: var(--color-emerald);
}

.button:disabled {
    background: var(--color-pale-gray);
    color: var(--color-dark-gray);
}

/* FILLED */

.button--filled {
    background: var(--color-emerald);
    color: var(--color-white);
}

.button--filled:hover {
    background: var(--color-light-emerald);
    color: var(--color-white);
    transition-duration: 1s;
}

.button--filled:focus-visible {
    background: var(--color-light-emerald);
    color: var(--color-white);

    outline: 3px solid var(--color-light-emerald);
    outline-offset: 2px;
}

.button--filled:active {
    background: var(--color-dark-emerald);
    color: var(--color-white);
}

.button--filled:disabled {
    background: var(--color-gray);
    color: var(--color-white);
}

/* OUTLINED */

.button--outlined {
    border: 1px solid var(--color-emerald);
    background: var(--color-white);
    background: none;
    color: var(--color-emerald);
}

.button--outlined:hover {
    border: 1px solid var(--color-light-emerald);
    background: var(--color-light-emerald);
    color: var(--color-white);
}

.button--outlined:focus-visible {
    border: 1px solid var(--color-light-emerald);
    background: var(--color-light-emerald);
    color: var(--color-white);

    outline: 3px solid var(--color-light-emerald);
    outline-offset: 2px;
}

.button--outlined:active {
    border: 1px solid var(--color-dark-emerald);
    background: var(--color-white);
    color: var(--color-dark-emerald);
}

.button--outlined:disabled {
    border: 1px solid var(--color-gray);
    background: var(--color-white);
    color: var(--color-gray);
}

/* FILLED AND OUTLINED */

.button--filled-and-outlined {
    border: 1px solid var(--color-white);
    background: var(--color-emerald);
    color: var(--color-white);
}

.button--filled-and-outlined:hover {
    border: 1px solid var(--color-blue);
    background: var(--color-blue);
    color: var(--color-emerald);
}

.button--filled-and-outlined:focus-visible {
    border: 1px solid var(--color-blue);
    background: var(--color-blue);
    color: var(--color-emerald);

    outline: 3px solid var(--color-blue);
    outline-offset: 2px;
}

.button--filled-and-outlined:active {
    border: 1px solid var(--color-white);
    background: var(--color-emerald);
    color: var(--color-white);
}

.button--filled-and-outlined:disabled {
    border: 1px solid var(--color-gray);
    background: none;
    color: var(--color-gray);
}

/* large */
.button--large {
    padding: 10px 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 170%;
    border-radius: 3px;

    background: var(--color-white);
    color: var(--color-black);
}

.button--large:hover {
    background: var(--color-white);
    color: var(--color-black);

    box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.12);
}

.button--large:focus-visible {
    background: var(--color-white);
    color: var(--color-emerald);

    outline: 3px solid var(--color-emerald);
    outline-offset: 2px;
}

.button--large:active {
    background: var(--color-white);
    color: var(--color-emerald);
    box-shadow: none;
}

.button--large:disabled {
    background: var(--color-pale-gray);
    color: var(--color-dark-gray);
}

.button--simple {
    padding: 0;
    border-radius: unset;
    font-size: 20px;
}

.button--simple:hover {
    background: none;
    color: var(--color-emerald);
}




/* filled-white */

.button--filled-white {
    background: var(--color-white);
    color: var(--color-emerald);
}

.button--filled-white:hover {
    background: var(--color-light-emerald);
    color: var(--color-white);
    transition-duration: 1s;
}

.button--filled-white:focus-visible {
    background: var(--color-light-emerald);
    color: var(--color-white);

    outline: 3px solid var(--color-light-emerald);
    outline-offset: 2px;
}

.button--filled-white:active {
    background: var(--color-dark-emerald);
    color: var(--color-white);
}

.button--filled-white:disabled {
    background: var(--color-gray);
    color: var(--color-white);
}


/* outlined-white */

.button--outlined-white {
    border: 1px solid var(--color-white);
    background: none;
    color: var(--color-white);
}

.button--outlined-white:hover {
    border: 1px solid var(--color-light-emerald);
    background: var(--color-light-emerald);
    color: var(--color-white);
}

.button--outlined-white:focus-visible {
    border: 1px solid var(--color-light-emerald);
    background: var(--color-light-emerald);
    color: var(--color-white);

    outline: 3px solid var(--color-light-emerald);
    outline-offset: 2px;
}

.button--outlined-white:active {
    border: 1px solid var(--color-dark-emerald);
    background: var(--color-white);
    color: var(--color-dark-emerald);
}

.button--outlined-white:disabled {
    border: 1px solid var(--color-gray);
    background: var(--color-white);
    color: var(--color-gray);
}