.LogosPanel {
    display: inline-flex;
    gap: 54px;
}

.Image {
    max-width: 170px;
}

/* Tablet */
@media screen and (max-width: 1023px) {
    .LogosPanel {
        gap: 24px;
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {
    .Image {
        max-width: 96px;
    }
}