.Paragraphs {
    display: flex;
    flex-direction: column;
    gap: 20px
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
}

/* Smartphone */
@media screen and (max-width: 767px) {
}