.HeaderSection {
    padding-bottom: 0;
}

.SupportPhone {
    font-weight: 800;
    font-size: 30px;
    line-height: 120%;
}

.Email {
    color: var(--color-white);
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 170%;
}