.program-info-text > ul {
    margin: 0;
    position: relative;
    left: -24px;
    list-style-image: none;
}

.program-info-text > ul > li {
    margin: 0;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}