.HeaderSection {
    padding-top: 20px;
    background-image: url(../../../../public/static/img/backgrounds/background1.png);
    background-size: cover;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
}


/* Smartphone */
@media screen and (max-width: 767px) {
}