.Carousel {}

.Carousel__wrapper {
    flex-grow: 1;
    box-sizing: border-box;
    overflow-x: hidden;
}

.Carousel__carousel {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    transition: all 300ms cubic-bezier(0.61, 1, 0.88, 1);
    width: 100%;
    box-sizing: border-box;
}

.Carousel__arrow {
    flex-shrink: 0;
    width: 32px;
    cursor: pointer;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
    border: none;
    background: none;
}

.Carousel__arrow:disabled {
    cursor: auto;
}

.Carousel__arrow path {
    stroke: #0096A5;
}

.Carousel__arrow:hover path {
    stroke: #008795;
}

.Carousel__arrow:disabled path {
    stroke: #C4C4C4;
}