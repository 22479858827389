.page-section {
    padding: 120px 150px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    text-align: left;
}

.page-section--color-white {
    background-color: var(--color-white);
}

.page-section--color-black {
    background-color: var(--color-black);
}

.page-section--color-burgundy {
    background-color: var(--color-burgundy);
}

.page-section--color-blue {
    background-color: var(--color-blue);
}

.page-section--color-emerald {
    background-color: var(--color-emerald);
}

.page-section--color-dark-emerald {
    background-color: var(--color-dark-emerald);
}

.page-section--color-light-emerald {
    background-color: var(--color-light-emerald);
}

.page-section--color-gray {
    background-color: var(--color-gray);
}

.page-section--color-dark-gray {
    background-color: var(--color-dark-gray);
}

.page-section--color-light-gray {
    background-color: var(--color-light-gray);
}

.page-section--color-pale-gray {
    background-color: var(--color-pale-gray);
}

@media screen and (max-width: 1056px) {
    .page-section {
        padding: 40px 50px;
        gap: 40px;
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {
    .page-section {
        padding: 40px 20px;
        gap: 30px;
    }
}