.TwoColsUl {
    column-count: 2;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {}

/* Smartphone */
@media screen and (max-width: 767px) {
    .TwoColsUl {
        column-count: 1;
    }
}