.MainMenu {
    display: flex;
    column-gap: 40px;
    row-gap: 20px;
    flex-wrap: wrap;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
}

/* Smartphone */
@media screen and (max-width: 767px) {
    .MainMenu {
        gap: 10px;
    }
}