.arrow-pivot-item {
    display: flex;
    cursor: pointer;
}

.arrow-pivot-item__text {
    flex-grow: 1;
    background-color: var(--color-light-gray);
    padding: 12px 16px;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
}

.arrow-pivot-item__text--active {
    background-color: var(--color-emerald);
    padding: 12px 16px;
    border-radius: 4px 0px 0px 4px;
}

.arrow-pivot-item__image {
    width: 30px;
    flex-shrink: 0;
}
