.card {
    /* padding: 28px 28px 32px; */
    padding: 18px 18px 22px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.card--clickable {
    cursor: pointer;
}

.card--clickable:hover {
    /* box-shadow: 12px 12px 30px rgba(0, 0, 0, 0.12); */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    /* animation: appear 400ms cubic-bezier(0.61, 1, 0.88, 1) 0s 1 normal none; */
}


@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}