.text {
    pointer-events: none;
}

.shadow {
    cursor: pointer;
    display: block;
    user-select: none;
}

.shadow:hover {

    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5)) brightness(0.9);
    /* filter: brightness(0.9); */
}