h1 {
    margin: 0;
    font-weight: 700;
    font-size: 46px;
    line-height: 110%;
}

h2 {
    margin: 0;
    /* font-family: var(--font-montserrat); */
    font-weight: 800;
    font-size: 30px;
    line-height: 120%;
}

h3 {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {}

/* Smartphone */
@media screen and (max-width: 767px) {
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 22px;
    }
}