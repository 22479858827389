.SubscribeForm {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.Form {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.Link {
    margin-left: 6px;
}