.menu-dropdown {
    display: inline-flex;
    flex-direction: column;
    position: relative;
}

/* BUTTON */

.menu-dropdown__button {
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black);
    display: flex;
    gap: 4px;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
}

.menu-dropdown__button:hover {
    color: var(--color-emerald);
}

.menu-dropdown__button:focus-visible {
    border-bottom: 1px solid var(--color-emerald);
    outline: 2px solid var(--color-emerald);
    outline-offset: 2px;
    border-radius: 4px;
}

/* DROPDOWN */

.menu-dropdown__dropdown {
    padding: 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 26px;
    z-index: 1000;
}

.menu-dropdown__link {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black);
    white-space: nowrap;
}

.menu-dropdown__link:hover {
    text-decoration: underline;
    color: var(--color-emerald);
}

.menu-dropdown__link:focus-visible {
    color: var(--color-emerald);
    text-decoration: underline;
    outline: 2px solid var(--color-emerald);
    outline-offset: 2px;
    border-radius: 4px;
}

.menu-dropdown__link--current {
    color: var(--color-emerald);
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {}

/* Smartphone */
@media screen and (max-width: 767px) {
    .menu-dropdown__dropdown {
        padding: 14px;
    }
    .menu-dropdown__link {
        font-size: 14px;
    }
}