.NewsContent {
    /* overflow-x: hidden; */
    /* text-overflow: ellipsis; */
}

.NewsContent img {
    width: 100%;
    object-fit: cover;
    /* display: none; */
}

.NewsContent a {
    font-size: 20px;
}