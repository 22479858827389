.Button {
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 6px 34px;
    cursor: pointer;
    box-sizing: border-box;
}

.Button--primary {
    background: #2B9FAC;
}

.Button--primary:hover {
    background-color: #258792;
    /* background: 'linear-gradient(to left, #258792, #2B9FAC)'; */
}