.dropdown {
    display: flex;
    flex-direction: column;
    position: relative;
}

/* BUTTON */

.dropdown__button {
    padding: 0;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: var(--color-black);

    display: flex;
    gap: 4px;
    border: none;
    border-bottom: 1px solid var(--color-dark-gray);

    background: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 1px;
}

.dropdown__button:hover {}

.dropdown__button:focus-visible {
    outline: none;
    border-bottom: 2px solid var(--color-dark-gray);
    margin-bottom: 0;
}

.dropdown__text {
    flex-grow: 1;
    text-align: left;
}

/* DROPDOWN */

.dropdown__dropdown {
    /* padding: 0 0; */
    display: flex;
    /* gap: 16px; */
    flex-direction: column;
    background: var(--color-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: absolute;
    top: 32px;
    z-index: 1000;
    min-width: 100%;
}

/* OPTION */

.dropdown__option {
    padding: 10px 16px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black);
    white-space: nowrap;
}

.dropdown__option:hover {
    background: var(--color-light-gray);
}

.dropdown__option:focus-visible {
    color: var(--color-emerald);
    text-decoration: underline;
    outline: 2px solid var(--color-emerald);
    outline-offset: 2px;
    border-radius: 4px;
}

.dropdown__option--current {
    font-weight: 500;
}