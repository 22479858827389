.checkbox {
    display: inline-flex;
    /* align-items: center; */

    padding-left: 32px;
    cursor: pointer;
    user-select: none;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.checkbox__input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: absolute;
}

.checkbox__box {
    position: absolute;
    margin-left: -32px;
    width: 20px;
    height: 20px;
    background-image: url(../../../public/static/img/checkbox/off.svg);
}

.checkbox__input:checked+.checkbox__box {
    background-image: url(../../../public/static/img/checkbox/on.svg);
}

.checkbox__input:checked:focus-visible+.checkbox__box {
    width: 26px;
    height: 26px;
    margin-top: -3px;
    margin-left: -35px;
    background-image: url(../../../public/static/img/checkbox/on-focus.svg);
}

.checkbox__input:not(:checked):focus-visible+.checkbox__box {
    width: 26px;
    height: 26px;
    margin-top: -3px;
    margin-left: -35px;
    background-image: url(../../../public/static/img/checkbox/off-focus.svg);
}

.checkbox__input:checked:disabled+.checkbox__box {
    background-image: url(../../../public/static/img/checkbox/on-disabled.svg);
}

.checkbox__input:not(:checked):disabled+.checkbox__box {
    background-image: url(../../../public/static/img/checkbox/off-disabled.svg);
}

/* White theme */

.checkbox__box--white-theme {
    background-image: url(../../../public/static/img/checkbox/white-theme/off.svg);
}

.checkbox__input:checked+.checkbox__box--white-theme {
    background-image: url(../../../public/static/img/checkbox/white-theme/on.svg);
}

.checkbox__input:checked:focus-visible+.checkbox__box--white-theme {
    width: 26px;
    height: 26px;
    margin-top: -3px;
    margin-left: -35px;
    background-image: url(../../../public/static/img/checkbox/white-theme/on-focus.svg);
}

.checkbox__input:not(:checked):focus-visible+.checkbox__box--white-theme {
    width: 26px;
    height: 26px;
    margin-top: -3px;
    margin-left: -35px;
    background-image: url(../../../public/static/img/checkbox/white-theme/off-focus.svg);
}

.checkbox__input:checked:disabled+.checkbox__box--white-theme {
    background-image: url(../../../public/static/img/checkbox/white-theme/on-disabled.svg);
}

.checkbox__input:not(:checked):disabled+.checkbox__box--white-theme {
    background-image: url(../../../public/static/img/checkbox/white-theme/off-disabled.svg);
}