.Image {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    object-fit: cover;
    max-height: 240px;
}

.Card {
    padding: 0;
}

.Info {
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 32px;
}