.text-field {
    display: inline-flex;
    flex-direction: column;
    gap: 6px;
}

.text-field--block-element {
    display: flex;
}
.text-field--full-width {
    width: 100%;

}

.input {
    margin: 0;
    padding: 8px 0px;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black);
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--color-dark-gray);
    margin-bottom: 1px;
}

.input::placeholder {
    color: var(--color-black);
}

.input:focus {
    outline: none;
    border-bottom: 2px solid var(--color-dark-gray);
    margin-bottom: 0;
}

.input--full-width {
    width: 100%;
}

.input--theme-white {
    border-bottom: 1px solid var(--color-white);
    color: var(--color-white);
}

.input--theme-white:focus {
    outline: none;
    border-bottom: 2px solid var(--color-white);
    margin-bottom: 0;
}


.input--theme-white::placeholder {
    color: var(--color-white);
}

.error {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-burgundy);
}