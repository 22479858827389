.text--extra-large {
    font-weight: 400;
    font-size: 46px;
    line-height: 110%;
}

.text--large {
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
}

.text--medium {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
}

.text--normal {
    font-weight: 400;
    font-size: 20px;
    line-height: 170%;
}

.text--small {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.text--tiny {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
}

.text--bold {
    font-weight: 500;
}

.text--uppercase {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.text--color-white {
    color: var(--color-white);
}

.text--color-black {
    color: var(--color-black);
}

.text--color-burgundy {
    color: var(--color-burgundy);
}

.text--color-blue {
    color: var(--color-blue);
}

.text--color-emerald {
    color: var(--color-emerald);
}

.text--color-dark-emerald {
    color: var(--color-dark-emerald);
}

.text--color-light-emerald {
    color: var(--color-light-emerald);
}

.text--color-gray {
    color: var(--color-gray);
}

.text--color-dark-gray {
    color: var(--color-dark-gray);
}

.text--color-light-gray {
    color: var(--color-light-gray);
}

.text--color-pale-gray {
    color: var(--color-pale-gray);
}