:root {
  --color-emerald: #459689;
  --color-dark-emerald: #24685F;
  --color-light-emerald: #699D9A;

  --color-gray: #B2B2B2;
  --color-dark-gray: #636F7A;
  --color-light-gray: #E9F0F4;
  --color-pale-gray: #D4D4D4;

  --color-black: #0F1010;
  --color-white: #FFFFFF;
  --color-burgundy: #85180F;
  --color-blue: #CEE1EC;

  --font-ibm: 'IBM Plex Sans';
  --font-montserrat: 'Montserrat';

  --max-width: 854px;
}

@import url(./headings.css);
@import url(./texts.css);

body {
  margin: 0;
  height: 100%;
  font-family: var(--font-ibm);
  font-style: normal;
  font-size: 20px;
  color: var(--color-black);
}

p {
  margin: 0;
  min-height: 1px;
}

p:not(:last-of-type) {
  margin-bottom: 20px;
}

b {
  font-weight: 500;
}

a {
  font-family: var(--font-ibm);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-emerald);
}

a:focus-visible {
  border-radius: 4px;
  outline: 2px solid var(--color-emerald);
  outline-offset: 4px;
}

button {
  font-family: var(--font-ibm);
}

.link--dashed {
  text-decoration: none;
  border-bottom: 1px dashed;
}

.color-white {
  color: var(--color-white);
}

.color-white:focus-visible {
  outline-color: var(--color-white);
}

ul {
  margin: 0;
  position: relative;
  left: -24px;
  list-style-image: url(../../public/static/img/dot--emerald.svg);
}

li {
  margin: 0;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

th {
  padding: 12px;
  border: 1px solid var(--color-gray);
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

td {
  padding: 12px;
  border: 1px solid var(--color-gray);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {}

/* Smartphone */
@media screen and (max-width: 767px) {}