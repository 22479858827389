.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1200;
}

.modal--hidden {
    display: none;
}

.modal__card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal__top-panel {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
}

.modal__heading {
    flex-grow: 1;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
}

.modal__button {
    padding: 0;
    outline: none;
    cursor: pointer;
    margin: 0;
    border: none;
    background-color: unset;
    color: var(--color-black);
    display: flex;
    align-items: center;
}

.modal__button:hover {
    color: var(--color-emerald);
}

.modal__button:focus-visible {
    color: var(--color-emerald);

    outline: 3px solid var(--color-blue);
    outline-offset: 2px;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
    .modal__card {
        left: 25%;
        transform: translate(-15%, -50%);
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {
    .modal__card {
        left: 10%;
        /* -webkit-transform: translate(-25%, -25%); */
        transform: translate(-5%, -50%);
    }
}