.menu-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-white);
    text-decoration: none;
    cursor: pointer;
}

.menu-link:hover {
    color: var(--color-light-gray);
    text-decoration: underline;
}

.menu-link:focus-visible {
    color: var(--color-emerald);
    text-decoration: underline;
    outline: 2px solid var(--color-emerald);
    outline-offset: 2px;
    border-radius: 4px;
}

.menu-link--current {
    color: var(--color-emerald);
}