.HeaderSection {
    padding-top: 20px;
}

.TopPanel {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
}


/* Smartphone */
@media screen and (max-width: 767px) {
}