.Image {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    max-width: 426px;
    max-height: 467px;
    background-color: var(--color-white);
    object-fit: cover;
}

.Info {
    height: 100%;
}

.FlexGrow {
    flex-grow: 1;
}

.Card {
    /* width: 100%; */
    flex-grow: 1;
    transition: height 0.2s ease-in;
}

.WillKnow {
    column-count: 3;
}

.WillKnow p:not(:last-child) {
    padding-bottom: 20px;
}

.WillKnow div:not(:last-child) {
    padding-bottom: 20px;
}

.WillBeAble {
    column-count: 3;
}

.WillBeAble p:not(:last-child) {
    padding-bottom: 20px;
}

.WillBeAble div:not(:last-child) {
    padding-bottom: 20px;
}

.Description p:not(:last-child) {
    padding-bottom: 20px;
}

.Description div:not(:last-child) {
    padding-bottom: 20px;
}

.ProgramTimetable {
    max-width: 854px;
    padding: 0;
}

.ProgramTimetable tr {
    padding: 0;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
    .WillKnow {
        column-count: 2;
    }

    .WillBeAble {
        column-count: 2;
    }
    .Image {
        display: none;
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {
    .WillKnow {
        column-count: 1;
    }

    .WillBeAble {
        column-count: 1;
    }
    .Image {
        display: none;
    }
}