.Link {
    font-weight: 400;
    font-size: 20px;
    line-height: 170%;
    text-decoration: none;
}

.SupportPhone {
    font-family: 'Montserrat';
    font-weight: 800;
    font-size: 30px;
    line-height: 120%;
}

.SocialLink {
    display: flex;
    align-items: 'center';
}

.MapImage {
    width: 100%;
}