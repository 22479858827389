.HorizontalStack {
    display: grid;
    grid-template-columns: 65% 1fr;
    gap: 20px;
}

.Li {
    padding: 10px 0;
    padding-left: 4px;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
    .HorizontalStack {
        grid-template-columns: 1fr;
        gap: 40px;
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {}