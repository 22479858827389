.Stack {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 20px;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
    .Stack {
        grid-template-columns: 1fr;
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {}