.textarea {
    margin: 0;
    padding: 8px 0px;

    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--color-black);
    background: none;
    border: none;
    border-radius: 0;
    border: 1px solid var(--color-dark-gray);
    margin: 1px;
}

.textarea::placeholder {
    color: var(--color-black);
}

.textarea:focus {
    outline: none;
    border: 2px solid var(--color-dark-gray);
    margin: 0;
}