.ArchivalPrograms {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {}

/* Smartphone */
@media screen and (max-width: 767px) {
    .ArchivalPrograms {
        grid-template-columns: 1fr;
    }
}