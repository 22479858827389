.FooterSection {
    color: var(--color-white);
}

/* Tablet */
@media screen and (max-width: 1023px) {
    .Stack {
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {
    .MapImage {
        /* max-width: 320px; */
        width: 100%;
    }
}