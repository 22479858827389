.MaxWidth {
    max-width: var(--max-width);
}

/* Tablet and Smartphone */
@media screen and (max-width: 1023px) {
    .MaxWidth {
        max-width: none
    }
}

/* Smartphone */
@media screen and (max-width: 767px) {
}